<template>
  <div class="views-page is-flex is-flex-direction-column px-4 py-4">
    <div
      :class="GetMessagesData.main_color"
      class="info-line"
    ></div>
    <div class="column p-0 tag-parent-mobile is-flex is-flex-wrap-wrap">
      <span
        v-for="msg in GetMessagesData.cards"
        :key="msg.id"
        :class="msg.color"
        class="tag-mobile is-size-6 has-text-weight-medium tag px-4 py-4 mr-3 mb-2"
      >{{ msg.name }}</span>
    </div>
    <div
      id="chartdiv"
      ref="chartdiv"
    />
    <b-loading
      v-model="isLoading"
      :can-cancel="false"
      :is-full-page="false"
    />
    <div>
      <div class="is-flex">
        <b-field label="Выберите канал">
          <!-- @input="SendData" -->
          <b-select
            v-model="selectedSpectrumChannel"
            placeholder="название канала"
            :loading="isTypesLoading"
            @input="fetchByDate(selected)"
          >
            <option
              v-for="(channel, idx) in spectraChannels"
              :key="`spectra-${idx}`"
              :value="channel"
            >
              {{ channel }}
            </option>
          </b-select>
        </b-field>
        <div class="mx-4 is-flex is-flex-direction-column mb-3">
          <p class="has-text-centered	">Период отображения данных:</p>
          <a
            :class="{ highlight: selected === 1 }"
            class="button is-small mr-1"
            @click="fetchByDate(1)"
          >Сегодня
          </a>
          <a
            :class="{ highlight: selected === 7 }"
            class="button is-small mr-1"
            @click="fetchByDate(7)"
          >Неделя
          </a>
          <a
            :class="{ highlight: selected === 30 }"
            class="button is-small mr-1"
            @click="fetchByDate(30)"
          >Месяц
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";

export default {
  props: ["device_id"],
  data: () => ({
    chart: null,
    selectedSpectrumChannel: undefined,
    spectraData: [],
    spectraTypes: [],
    spectraChannels: [],
    ListSelect: {},
    isLoading: false,
    isTypesLoading: true,
    selected: null,
    isShown: false,
    currentData: null,
    trendColor: ["#FAA700", "#6DD400", "#0091FF", "#6236FF", "#B620E0", "#9B9B9B", "#44D7B6", "#0D7B80", "#00BDB7"],
  }),
  computed: mapGetters(["GetMessagesData"]),
  watch: {
    selectedSpectrumChannel: {
      immediate: false,
      handler(newValue, oldValue) {
        console.log(newValue);
        if(newValue !== oldValue && oldValue) this.fetchByDate(this.selected, true)
      }
    },

    spectraData(newValue, oldValue) {
      if (oldValue !== newValue && this.chart) {
        const count = this.chart.series.length || 0

        for (let i = 0; i < count; i++) {
          this.chart.series.removeIndex(0).dispose();
        }

        this.spectraTypes.forEach((type, idx) => {
          this.createSeries(`avg${idx+1}`, type, idx, this.trendColor[idx])
        })

        this.chart.data = newValue;
      }
    },
  },
  mounted() {
    // this.device_id = this.$router.history.current.params.id.replace(/[^0-9]/g,"");
    this.fetchSpectraTypes();

    // this.device_id = this.$router.history.current.params.id.replace(/[^0-9]/g,"");
    this.FetchMessages(this.device_id);
    //create an instance of amCharts
    this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart3D);
    this.chart.depth = 300;
    this.chart.paddingRight = 20;
    //language
    this.chart.language.locale = am4lang_ru_RU;

    // Add data
    // this.chart.data = this.DataOfSpectors;

    // Create axes date X
    var dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 80;


    // First value axis Y
    var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "мм/с";
    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.title.rotation = 90;
    valueAxis.title.valign = "top";
    valueAxis.title.fontWeight = '600';

    this.ValueAxis = valueAxis;

    //create legend of series
    this.chart.legend = new am4charts.Legend();

    //create event to cursor
    this.chart.cursor = new am4charts.XYCursor();
    this.chart.cursor.maxTooltipDistance = -1;

    this.chart.scrollbarX = new am4core.Scrollbar();

    this.chart.zoomOutButton.align = "left";
    this.chart.zoomOutButton.valign = "top";
    this.chart.zoomOutButton.marginLeft = 10
  },
  beforeDestroy() {
    this.chart.dispose()
  },
  methods: {
    ...mapActions(["FetchMessages"]),
    async fetchSpectraTypes() {
      this.isTypesLoading = true;
      try {
        const { data } = await this.$axios.get(`user/agents/${this.device_id}/trend-specter-types`);
        this.spectraChannels = data?.data?.channel || [];
      } catch (e) {
        throw new Error(e);
      }
      this.isTypesLoading = false;
    },
    async fetchSpectraData(payload) {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/trend-specters-v2`, payload);
        this.spectraData = data?.data || [];
        this.spectraTypes = data?.names || [];
      } catch (e) {
        throw new Error(e);
      }
      this.isLoading = false;
    },
    createSeries(field, name, zIndex, color) {
      const series = this.chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "time";
      series.name = name;
      series.stroke = color;
      // series.tooltip.fillcolor = color;
      // series.tooltipText = "{name} {dateX}: [b]{valueY}[/]";
      series.tooltipText = "{name}: [b]{valueY}[/]";
      const chart = this.chart;
      series.adapter.add("tooltipText", function(text) {
        text = "[bold]{dateX}[/]\n";
        chart.series.each(function(item) {
          if ( !item.isHidden && !["alarm", "warning"].includes(item.dataFields.valueY)) {
            text += "[" + item.stroke?.hex + "]●[/] " + item.name + ": [bold]{" + item.dataFields.valueY + "}[/]\n";
          }
        });
        return text;
      });
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#fff");
      series.tooltip.label.fill = am4core.color("#00");
      series.strokeWidth = 2;
      series.zIndex = zIndex;
      series.dx = this.chart.depth / (this.spectraTypes.length) * am4core.math.cos(this.chart.angle) * zIndex;
      series.dy = -this.chart.depth / (this.spectraTypes.length) * am4core.math.sin(this.chart.angle) * zIndex;
      // series.yAxis = valueAxis;
      return series;
    },
    fetchByDate(days, fetchAgain = false) {
      if(this.selected !== days || fetchAgain) {
        this.selected = days;

        let from = new Date();
        let today = Date.now();

        const fromTo = days < 30 ? from.setDate(from.getDate() - days) : from.setMonth(from.getMonth() - 1);

        const payload = {
          channel: this.selectedSpectrumChannel,
          to: today,
          from: fromTo,
        };

        this.fetchSpectraData(payload);
      }
    },
  },
};
</script>

<style scoped>
.highlight {
  background: #61a937;
}
</style>
