<template>
  <div>
    <div class="columns pt-3">
      <div class="column heading-style">Все предприятия</div>
    </div>
    <!-- модальное окно -->
    <div
      class="modal"
      :class="[isActive && 'is-active']"
    >
      <div
        @click="isActive = true"
        class="modal-background"
      />
      <div class="modal-content">
        <Modal :selected="selected" :show-modal="isActive" @close="isActive = false" />
      </div>
      <button
        @click="isActive = false"
        class="modal-close is-large"
        aria-label="close"
      />
    </div>
    <!-- конец -->
    <div class="columns">
      <section class="column is-size-6">
        <b-table
          :data="companies"
          :hoverable="true"
        >
          <b-table-column
            headerClass="has-text-weight-bold"
            label="Город"
            centered
            v-slot="props"
          >
            {{ props.row.factory.branch.name }}
          </b-table-column>
          <b-table-column
            headerClass="has-text-weight-bold"
            label="Предприятие"
            centered
            v-slot="props"
          >
            {{ props.row.factory.branch.company.name }}
          </b-table-column>

          <b-table-column
            headerClass="has-text-weight-bold"
            label="Цех"
            centered
            v-slot="props"
          >
            {{ props.row.factory.name }}
          </b-table-column>

          <b-table-column
            headerClass="has-text-weight-bold"
            label="Агрегат"
            centered
            v-slot="props"
          >
            {{ props.row.device_name }}<br />
            <p
              class="is-size-6 tag my-1"
              :class="card.color"
              v-for="(card,id) in props.row.cards"
              :key="id"
            >
              {{ card.name }}
            </p>
          </b-table-column>

          <b-table-column
            headerClass="has-text-weight-bold"
            label="Сообщение мониторинга"
            centered
            v-slot="props"
          >
            <p
              class="is-size-6"
              :class="props.row.last_message.color"
              v-if="props.row.last_message"
            >
              {{ props.row.last_message.text }} <br>
              <span class="has-text-grey-light text-font-size-span">{{ props.row.updated_at }}</span>
            </p>
            <p v-else>Сообщений нет</p>
          </b-table-column>

          <b-table-column
            headerClass="has-text-weight-bold"
            label="Наши действия"
            v-slot="props"
            width="100"
          >
            <div class="buttons is-right">
              <button
                v-if="!props.row.comment"
                type="button"
                class="button is-info"
                @click="openModal(props.row)"
              >Отработать...
              </button>
              <p v-else>{{ props.row.comment }}</p>
            </div>
          </b-table-column>
        </b-table>
      </section>
    </div>
  </div>
</template>

<script>
import Modal from "./Global-Modal"

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isActive: false,
      total: 20,
      current: 10,
      perPage: 10,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      hasInput: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      inputPosition: "",
      inputDebounce: "",
      Agents: [],
      selected: null,
      companies: [],
      GetLastMsg: [],
    }
  },
  created() {
    this.fetchCompanies()
  },
  methods: {
    async fetchCompanies() {
      try {
        const { data } = await this.$axios.get(`manager/agents`)
        this.companies = data?.data || []
      } catch (e) {
        throw new Error(e)
      }
    },
    openModal(row) {
      this.selected = row
      this.isActive = true
    }
  },
}
</script>

<style scoped>
.modal-content {
  width: 1200px;
  height: 700px;
}

.heading-style {
  font-size: 30px;
  color: rgb(0, 0, 0);
  font-weight: 700;
}

.text-font-size-span {
  font-size: 14px;
}
</style>
