<template>
  <div
    v-if="showModal && selected"
    class="modal-style columns p-3 is-gapless"
  >
    <div class="column is-7">
      <p class="has-text-grey is-size-6 my-2">
        {{ selected.factory.branch.name }} > {{ selected.factory.branch.company.name }} > {{ selected.factory.name }}
      </p>
      <p class="is-size-3  has-text-weight-medium my-2">{{ LastMessages.device_name }}</p>
      <div class="buttons">
        <button
          class="style-of-btn"
          :class="[Views && 'active-btn']"
          @click="Views = true"
        >Обзор
        </button>
        <button
          class="style-of-btn"
          :class="[Messages && 'active-btn']"
          @click="Messages = true"
        >Сообщения
        </button>
        <button
          class="style-of-btn"
          :class="[Trends && 'active-btn']"
          @click="Trends = true"
        >Тренды
        </button>
        <button
          class="style-of-btn"
          :class="[Spectors && 'active-btn']"
          @click="Spectors = true"
        >Спектры
        </button>
        <button
          class="style-of-btn"
          :class="[Reports && 'active-btn']"
          @click="Reports = true"
        >Тренды Спектров
        </button>
      </div>
      <div v-if="Views">
        <div class="column tag-parent-mobile">
          <span
            class="tag-mobile is-size-6 tag px-4 py-4 mr-3"
            v-for="(item,id) in LastMessages.cards"
            :key="id"
            :class="item.color"
          >{{ item.name }}</span>
        </div>
        <div class="mb-2  px-3 py-3 style-of-vibs">
          <p>Скорость, об/мин</p>
          <p
            v-if="LastMessages.current_values && LastMessages.current_values.speed"
            class="has-text-weight-bold font-size-vibro has-text-success"
          >{{ LastMessages.current_values.speed }}</p>
          <p
            class="has-text-weight-bold font-size-vibro has-text-success"
            v-else
          >0</p>
        </div>
        <div class="style-of-vibs px-3 py-3">
          <p>Виброскорость, м/с</p>
          <div
            v-if="LastMessages.current_values && LastMessages.current_values.vibs.length != 0"
            class="columns is-flex is-flex-wrap-wrap"
          >
            <div
              class="column px-2"
              style="flex: 0 0 25%"
              v-for="(lastMsg, id) in LastMessages.current_values.vibs"
              :key="lastMsg.id"
            >
              <p
                class="has-text-weight-bold px-2  font-size-vibro"
                :class="lastMsg.color"
              >{{ lastMsg.value }}</p>
              <p class="px-2">{{ lastMsg.name }}</p>
            </div>
          </div>
          <div
            v-else
            class="columns v-fake is-flex is-flex-wrap-wrap"
          >
            <div
              class="column"
              v-for="(lastMsg,id) in SizeOfVibs"
              :key="id"
            >
              <p class="has-text-weight-bold font-size-vibro has-background-gray">0</p>
              <p class="">Канал</p>
            </div>
          </div>
        </div>
        <div>
          <!-- <p v-for="unread in UnReadMessages.data" :key="unread.id">{{unread.msg}}</p> -->
          <p>Уровень текущего износа — 12%</p>
        </div>
        <div class="has-background-warning my-3">
          <div class="is-flex px-3 py-3">
            <p>
              <font-awesome-icon
                class="px-1 py-1"
                :icon="['fas', 'fa-triangle-exclamation']"
              ></font-awesome-icon>
            </p>
            <div class="is-flex is-flex-direction-column">
              <p class="">{{ NotificationMsg.msg }}</p>
              <p class="has-text-grey has-text-weight-light">{{ NotificationMsg.created_at }}</p>
            </div>
          </div>
        </div>
        <div class="is-flex mt-3">
          <div class="is-flex has-text-grey mr-3 is-align-items-center">
            <font-awesome-icon
              class="px-1 py-1"
              :icon="['fas', 'fa-bolt']"
            ></font-awesome-icon>
            <p class="mx-1 my-1">1402 кВт/ч</p>
          </div>
          <div class="is-flex has-text-danger mr-3 is-align-items-center">
            <font-awesome-icon
              class="px-1 py-1"
              :icon="['fas', 'fa-temperature-quarter']"
            ></font-awesome-icon>
            <p class="mx-1 my-1">98 °С</p>
          </div>
          <div class="is-flex has-text-warning is-align-items-center">
            <font-awesome-icon
              class="px-1 py-1"
              :icon="['fas', 'fa-droplet']"
            ></font-awesome-icon>
            <p class="mx-1 my-1">0,1</p>
          </div>
        </div>
        <div>
          <p>{{ LastMessages.created_at }}</p>
        </div>
      </div>
      <div v-if="Messages">
        <div class="is-flex is-align-items-flex-start">
          <div class="column is-flex is-flex-wrap-wrap">
            <span
              class="tag-mobile tag is-size-6 px-4 py-4 mr-3 mb-2"
              v-for="(Msg,id) in LastMessages.cards"
              :key="Msg.id"
              :class="Msg.color"
            >{{ Msg.name }}</span>
          </div>
        </div>
        <div
          class="columns"
          v-for="(message, id) in AllAgentMsg"
          :key="id"
        >
          <div class="column is-2">
            {{ message.date }}
          </div>
          <div class="column has-text-danger">
            {{ message.msg }}
          </div>
        </div>
      </div>
      <div v-if="Trends">
        <Trends :device_id="selected.id" />
      </div>
      <div v-if="Spectors">
        <SpectorsComp :device_id="selected.id" />
      </div>
      <div v-if="Reports">
        <TrendSpectorsComp :device_id="selected.id" />
      </div>
    </div>
    <div class="column is-5">
      <div class="column right-bar-content">
        <div class="left-side-bar p-4">
          <p class="has-text-weight-bold font-style-cms-user mb-2">Ответственные лица заказчика</p>
          <div
            class="left-side-bar mb-1"
            v-for="user in UsersOfCms"
            :key="user.id"
          >
            <p>+{{ user.phone || "телефон" }}, {{ user.first_name || "имя от. лица" }} - {{ user.position || "должность" }}</p>
          </div>
        </div>
        <form @submit.prevent="SendEngineerMsg">
          <div class="mt-3 left-side-bar px-4 py-4">
            <p class="font-style-cms-user has-text-weight-bold mb-3">Комментарий инженера мониторинга</p>
            <textarea
              class="input-width p-1"
              v-model="engineerMsg"
              placeholder="Напишите, что вы сделали, чтобы решить проблему"
              rows="6"
              cols="50"
              name="text"
            ></textarea>
            <p class="my-2">Связанное сообщение мониторинга:</p>
            <select
              class="input input-width"
              placeholder="Выберите сообщение"
              v-model="SelectedUnreadMsg"
            >
              <option
                v-for="unread in UnReadMessages"
                :key="unread.id"
                :value="unread.id"
              >{{ unread.msg }}
              </option>
            </select>
            <div class="my-3">
              <input
                v-model="toArchive"
                type="checkbox"
                id="scales"
                name="scales"
              >
              <label for="scales"> Убрать агрегат из таблицы с ошибками</label>
              <p>
                Вы сделали всё, что нужно, в данной ситуации, агрегат отдельного мониторинга не требует
              </p>
              <button class="button is-info mt-5"> Оставить комментарии</button>
            </div>
          </div>
        </form>
        <div v-if="comments.length > 0" class="modal-left-content left-side-bar px-4">
          <p class="my-1 font-style-cms-user ">
            <b>Предыдущие комментарии</b>
          </p>
          <div
            v-for="message in comments"
            :key="message.id"
          >
            <div class="has-background-white mb-3 py-2">
              <p class="py-1 is-size-7 mx-2">{{ message.created_at }} · {{ message.user.first_name }} {{ message.user.last_name }}</p>
<!--              <p class="notification is-warning mb-0">{{ message.text }}</p>-->
              <p class="mx-2 my-1 has-text-weight-medium">{{ message.text }}</p>
            </div>
          </div>
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Trends from "../Trends.vue"
import SpectorsComp from "../Spectors.vue"
import TrendSpectorsComp from "../Trends-Spec.vue"

export default {
  components: {
    Trends, SpectorsComp, TrendSpectorsComp,
  },
  props: ["selected", "showModal"],
  data() {
    return {
      Views: true,
      Messages: false,
      Trends: false,
      Spectors: false,
      Reports: false,
      UsersOfCms: [],
      engineerMsg: "",
      AllAgentMsg: [],
      LastMessages: [],
      UnReadMessages: [],
      NotificationMsg: [],
      SelectedUnreadMsg: null,
      SizeOfVibs: 8,
      toArchive: true,
      comments: []
    }
  },
  watch: {
    SelectedUnreadMsg(newValue, oldValue) {
      if(newValue) {
        const message = this.UnReadMessages.find(msg => msg.id === newValue)
        this.comments = message?.['commentaries'] || []
      }
    },
    Views() {
      if (this.Views) {
        this.Messages = false
        this.Trends = false
        this.Spectors = false
        this.Reports = false
      }
    },
    Messages() {
      if (this.Messages) {
        this.Views = false
        this.Trends = false
        this.Spectors = false
        this.Reports = false
      }
    },
    Trends() {
      if (this.Trends) {
        this.Messages = false
        this.Views = false
        this.Spectors = false
        this.Reports = false
      }
    },
    Reports() {
      if (this.Reports) {
        this.Trends = false
        this.Messages = false
        this.Views = false
        this.Spectors = false
      }
    },
    Spectors() {
      if (this.Spectors) {
        this.Trends = false
        this.Messages = false
        this.Views = false
        this.Reports = false
      }
    },
    showModal() {
      this.engineerMsg = ''
      this.comments = []
      this.SelectedUnreadMsg = undefined
      this.Views = true
      this.FetchUsersOfCMS(this.selected.id)
      this.FetchMessages(this.selected.id)
      this.FetchAllMsgOfAgent(this.selected.id)
      this.FetchUnreadMessages(this.selected.id)
    }
  },
  methods: {
    async FetchUsersOfCMS(id) {
      await this.$axios.get(`user/agents/${id}/users`).then(response => {
        this.UsersOfCms = response.data.data
      })
    },
    async SendEngineerMsg() {
      let data = {
        "message_id": this.SelectedUnreadMsg,
        "text": this.engineerMsg,
        "to_archive": this.toArchive
      }
      await this.$axios.post(`user/commentaries`, data).then(response => {
        this.comments = [...this.comments, response.data?.data]
        this.engineerMsg = ""
        if(this.toArchive) this.$emit('close')
      })
    },
    async FetchAllMsgOfAgent(id) {
      await this.$axios.get(`user/agents/${id}/messages`).then(response => {

        this.AllAgentMsg = response.data?.data || []
      })
    },
    async FetchMessages(id) {
      await this.$axios.get(`user/agents/${id}/last-message`).then(response => {
        this.LastMessages = response.data.data

      })
    },
    async FetchUnreadMessages(id) {
      await this.$axios.get(`user/agents/${id}/unread-messages`).then(response => {
        this.UnReadMessages = response.data?.data || []
        this.NotificationMsg = this.UnReadMessages[0] || {}
      })
    },
  },
}
</script>

<style scoped>
.modal-left-content {
  width: 100%;
  overflow: auto;
}

.modal-style {
  font-size: 16px;
  background: #fff;
}

.input-width {
  width: 100%;
}

.style-of-vibs {
  width: 400px;
}

.font-size-vibro {
  font-size: 1.625rem;
}

.left-side-bar {
  background-color: #f7f7f7;
}

.buttons {
  margin-bottom: 0rem;
}

.right-bar-content {
  height: 630px;
  overflow: scroll;
  overflow-x: auto;
}

.font-style-cms-user {
  font-size: 18px;
}

.tag-parent-mobile {
  padding: 0;
  margin: 10px 0;
}

.style-of-btn {
  background: white;
  border: none;
  padding: 10px;
  font-size: 16px;
  color: #0091FF;
}

.style-of-btn:hover {
  cursor: pointer;
  background-color: #EDF2FA;
  color: #000;
  border-radius: 4px;
}

.active-btn {
  font-weight: 700;
  border-radius: 4px;
  color: #000;
  background-color: #EDF2FA;
}

.v-fake {
  width: 300px;
}
</style>
